import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useConfigurationService } from "~/src/services/ConfigurationService";
import { DEFAULT_REQUEST, useCaseTableStore } from "~/stores/caseTableStore";
import type { FrontendRequestObject } from "~/src/models/utils/Api.model";

export const useCaseFilters = async () => {
  const caseTableStore = useCaseTableStore();
  const { request } = storeToRefs(caseTableStore);

  const { useCaseTableFilterConfigQuery } = useConfigurationService();
  const { data: filterConfig } = await useCaseTableFilterConfigQuery();

  const defaultFilter = computed(() => filterConfig.value?.defaultFilter);
  const defaultVal = ref<FrontendRequestObject>(DEFAULT_REQUEST);

  const resetRequest = () => (request.value = defaultVal.value);

  const setDefaultFilter = (filter: FrontendRequestObject["filters"]) =>
    (defaultVal.value.filters = filter);

  if (defaultFilter.value) setDefaultFilter([defaultFilter.value]);

  return {
    defaultVal,
    filterConfig,
    resetRequest,
  };
};
